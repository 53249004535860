<template>
  <div>
    <div class="staffLogin" @click="toJNJUser">强生员工登录</div>
  </div>
</template>

<script>
import storage from "../../common/utils/storage";
import { mapState } from 'vuex'
export default {
  name: 'OpenJNJUser',
  data() {
    return {}
  },
  computed: {
    ...mapState(['Openid']),
    code() {
      return this.$route.query.code;
    },
  },
  created() {
    localStorage.setItem("Openid", this.Openid);

    if (this.code) {
      let Openid = localStorage.getItem("Openid");
      this.$axios.post('/Api/Api/Web/GetJNJUserByWeb/GetUser?OpenID=' + Openid + '&code=' + this.code).then(res => {
        if (res.RetCode == '10000') {
          let redirectUrl = sessionStorage.getItem("redirectUrl");
          if(redirectUrl) {
            this.$router.push(redirectUrl);
            sessionStorage.removeItem('redirectUrl');
          } else {
            this.$router.push('/');
          }
        } else {
          this.$toast(res.RetMsg);
        }
      })
    } else {
      console.log('code未获取');
    }
  },
  methods: {
    toJNJUser() {
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      let maxPos = chars.length;
      let nonce = '';
      for (let i = 0; i < 6; i++) {
        nonce += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      window.location.href = `https://fedlogin.jnj.com/as/authorization.oauth2?client_id=${process.env.VUE_APP_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_BASE_URL}/openJNJUser&scope=address%20email%20openid%20phone%20profile&nonce=${nonce}`
    }
  }
}
</script>

<style lang="scss" scoped>
.staffLogin{
  width: 170px;
  height: 49px;
  line-height: 49px;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
  background-color: #CA001B;
  color: #FFFFFF;
  margin: 20px auto;
}
</style>